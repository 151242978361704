<template>
    <div class="mainWrapper pb-0">
        <section class="blockElement space">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-4 mb-3 mobSticky">
                        <div class="getStart bg-white boxed box-shadow guide_lines mobScroll" id="getstarted">
                            <div class="repeatListFlex">
                                <div class="repeatList">
                                    <a @click.prevent="ZuluTradeTabs = 'Onboarding'" :class="(ZuluTradeTabs == 'Onboarding')?'active':''" class="js-anchor-link d-flex" href="#Onboarding">{{$t('user_guide_tabs.guidetab1')}} </a>
                                </div>
                                <div class="repeatList">
                                    <a @click.prevent="ZuluTradeTabs = 'Dashboard'" :class="(ZuluTradeTabs == 'Dashboard')?'active':''" class="js-anchor-link d-flex" href="#Dashboard">{{$t('user_guide_tabs.guidetab2')}} </a>
                                </div>
                                <div class="repeatList">
                                    <a @click.prevent="ZuluTradeTabs = 'Leaders'" :class="(ZuluTradeTabs == 'Leaders')?'active':''" class="js-anchor-link d-flex" href="#Leaders">{{$t('user_guide_tabs.guidetab3')}} </a>
                                </div>
                                <div class="repeatList">
                                    <a @click.prevent="ZuluTradeTabs = 'Markets'" :class="(ZuluTradeTabs == 'Markets')?'active':''" class="js-anchor-link d-flex" href="#Markets">{{$t('user_guide_tabs.guidetab4')}} </a>
                                </div>
                                <div class="repeatList">
                                    <a @click.prevent="ZuluTradeTabs = 'Community'" :class="(ZuluTradeTabs == 'Community')?'active':''" class="js-anchor-link d-flex" href="#Community">{{$t('user_guide_tabs.guidetab5')}} </a>
                                </div>
                                <div class="repeatList">
                                    <a @click.prevent="ZuluTradeTabs = 'Account'" :class="(ZuluTradeTabs == 'Account')?'active':''" class="js-anchor-link d-flex" href="#Account">{{$t('user_guide_tabs.guidetab6')}} </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-8">
                        <div class="bg-white boxed box-shadow p-md-4">
                            <!-- Main item -->
                            <section class="inner-box fullList" id="Onboarding">
                                <h1 class="f-25 tag medium border-bottom pb-1 mb-2">{{$t('onboarding.contenttitle1')}}</h1>
                                <!-- Accordion item -->
                                <div class="selected support_sec">
                                    <h4>{{$t('onboarding.contentsheet1')}} </h4>
                                    <p v-html="$t('onboarding.contentsheet2')"></p>
                                    <h4>{{$t('onboarding.contentsheet3')}}</h4>
                                    <!-- <p v-html="$t('onboarding.contentsheet4')"></p> -->
                                    <p>{{$t('onboarding.contentsheet5')}}</p>
                                    <ul class="dotList">
                                        <li>{{$t('onboarding.contentsheet6')}} </li>
                                        <li>{{$t('onboarding.contentsheet7')}} </li>
                                        <li>{{$t('onboarding.contentsheet8')}} </li>
                                    </ul>
                                    <div class="mb-3">
                                        <span v-html="$t('onboarding.contentsheet9')"></span>
                                    </div>
                                    <div class="inText">
                                        <h5>{{$t('onboarding.contentsheet10')}}</h5>
                                        <p v-html="$t('onboarding.contentsheet11')"></p>
                                        <p>{{$t('onboarding.contentsheet12')}}</p>
                                        <h5>{{$t('onboarding.contentsheet13')}}</h5>
                                        <p>{{$t('onboarding.contentsheet14')}}</p>
                                        <div class="inText">
                                            <h6>{{$t('onboarding.contentsheet15')}} </h6>
                                            <p>{{$t('onboarding.contentsheet16')}} </p>
                                            <p>{{$t('onboarding.contentsheet17')}}</p>
                                            <p>{{$t('onboarding.contentsheet18')}}</p>
                                            <p>{{$t('onboarding.contentsheet19')}}</p>
                                            <h6>{{$t('onboarding.contentsheet20')}}</h6>
                                            <p>{{$t('onboarding.contentsheet21')}}</p>
                                            <p>{{$t('onboarding.contentsheet22')}}</p>
                                            <p><i>{{$t('onboarding.contentsheet23')}}</i></p>
                                        </div>
                                        <h5>{{$t('onboarding.contentsheet40')}}</h5>
                                        <p>{{$t('onboarding.contentsheet41')}}</p>
                                        <!-- <h5>{{$t('onboarding.contentsheet24')}}</h5> -->
                                        <!-- <p>{{$t('onboarding.contentsheet25')}}</p> -->
                                        <!-- <p v-html="$t('onboarding.contentsheet26')"></p> -->
                                        <!-- <p>{{$t('onboarding.contentsheet27')}}</p> -->
                                        <!-- <p>{{$t('onboarding.contentsheet28')}}</p> -->
                                        <!-- <ul class="dotList">
                                            <li v-html="$t('onboarding.contentsheet29')"></li>
                                            <li>{{$t('onboarding.contentsheet30')}} </li>
                                            <li>{{$t('onboarding.contentsheet31')}}</li>
                                            <li>{{$t('onboarding.contentsheet32')}}</li>
                                            <li>{{$t('onboarding.contentsheet33')}}</li>
                                            <li>{{$t('onboarding.contentsheet34')}}</li>
                                        </ul> -->
                                        <!-- <p>To learn more you can read our <a class="secondary" target="_blank" href="javascript:void(0)">subscription plan policy</a> </p> -->
                                    </div>
                                </div>
                            </section>
                            <!-- Main item -->
                            <section class="inner-box fullList" id="Dashboard">
                                <h3 class="border-bottom pb-3 mb-3">{{$t('user_guide_dashboard.contenttitle2')}}</h3>
                                <!-- Accordion item -->
                                <div class="selected support_sec">
                                    <h4>{{$t('user_guide_dashboard.contentsheet35')}}</h4>
                                    <p>{{$t('user_guide_dashboard.contentsheet36')}}</p>
                                    <p>{{$t('user_guide_dashboard.contentsheet37')}}</p>
                                    <h4>{{$t('user_guide_dashboard.contentsheet38')}}</h4>
                                    <p>{{$t('user_guide_dashboard.contentsheet39')}}</p>
                                    <div class="inText">
                                        <h5>{{$t('user_guide_dashboard.contentsheet40')}}</h5>
                                        <p>{{$t('user_guide_dashboard.contentsheet41')}}</p>
                                        <h5>{{$t('user_guide_dashboard.contentsheet42')}}</h5>
                                        <p>{{$t('user_guide_dashboard.contentsheet62')}}</p>
                                        <p>{{$t('user_guide_dashboard.contentsheet43')}}</p>
                                        <p v-html="$t('user_guide_dashboard.contentsheet44')"></p>
                                        <h5>{{$t('user_guide_dashboard.contentsheet45')}}</h5>
                                        <p>{{$t('user_guide_dashboard.contentsheet46')}}</p> 
                                        <p>{{$t('user_guide_dashboard.contentsheet47')}}</p>
                                        <h5>{{$t('user_guide_dashboard.contentsheet48')}}</h5>
                                        <p>{{$t('user_guide_dashboard.contentsheet49')}}</p>
                                    </div>
                                    <h4>{{$t('user_guide_dashboard.contentsheet50')}}</h4>
                                    <p>{{$t('user_guide_dashboard.contentsheet51')}} </p>
                                    <div class="inText">
                                        <h5>{{$t('user_guide_dashboard.contentsheet52')}}</h5>
                                        <p>{{$t('user_guide_dashboard.contentsheet53')}}</p>
                                        <p>{{$t('user_guide_dashboard.contentsheet54')}}</p>
                                        <h5>{{$t('user_guide_dashboard.contentsheet55')}}</h5>
                                        <p>{{$t('user_guide_dashboard.contentsheet56')}}</p>
                                        <p v-html="$t('user_guide_dashboard.contentsheet57')"></p>
                                        <h5>{{$t('user_guide_dashboard.contentsheet58')}}</h5>
                                        <p>{{$t('user_guide_dashboard.contentsheet59')}}</p>
                                        <p>{{$t('user_guide_dashboard.contentsheet60')}}</p>
                                        <p>{{$t('user_guide_dashboard.contentsheet61')}}</p>
                                    </div>
                                </div>
                            </section>
                            <!-- Main item -->
                            <section class="inner-box fullList" id="Leaders">
                                <h3 class="border-bottom pb-3 mb-3">{{$t('user_guide_leaders.leadertitle')}}</h3>
                                <!-- Accordion item -->
                                <div class="selected support_sec">
                                    <p>{{$t('user_guide_leaders.leadercontent1')}} </p>
                                    <p>{{$t('user_guide_leaders.leadercontent2')}}</p>
                                    <ul class="numaricList">
                                        <li><strong>{{$t('user_guide_leaders.leadercontent3')}}</strong> {{$t('user_guide_leaders.leadercontent67')}}</li>
                                        <li><strong>{{$t('user_guide_leaders.leadercontent4')}}</strong> {{$t('user_guide_leaders.leadercontent68')}}</li>
                                        <li><strong>{{$t('user_guide_leaders.leadercontent5')}}</strong> {{$t('user_guide_leaders.leadercontent69')}}</li>

                                        <li><strong>{{$t('user_guide_leaders.leadercontent6')}} </strong> <a href='/autoprotect-your-account' target='_blank' class='secondary'> ZuluGuard™</a> {{$t('user_guide_leaders.leadercontent70')}} <a href='/autoprotect-your-account' target='_blank' class='secondary'> ZuluGuard™</a> {{$t('user_guide_leaders.leadercontent71')}}</li>
                                        <li>{{ $t('user_guide_leaders.leadercontent7') }}</li>
                                        <ul class="inText">
                                            <li><strong>{{$t('user_guide_leaders.leadercontent8')}}</strong> {{$t('user_guide_leaders.leadercontent72')}} </li>
                                            <li><strong>{{$t('user_guide_leaders.leadercontent9')}}</strong> {{$t('user_guide_leaders.leadercontent73')}}</li>
                                            <li><strong>{{$t('user_guide_leaders.leadercontent10')}} </strong> {{$t('user_guide_leaders.leadercontent74')}}</li>
                                            <li><strong>{{$t('user_guide_leaders.leadercontent11')}} </strong> {{$t('user_guide_leaders.leadercontent75')}}</li>

                                        </ul>
                                        <p><i>{{$t('user_guide_leaders.leadercontent12')}} <a href='/autoprotect-your-account' target=_blank class=secondary> ZuluGuard™</a> , {{$t('user_guide_leaders.leadercontent76')}}</i></p>
                                    </ul>
                                </div>
                            </section>
                                <!-- Main item -->
                            <section class="inner-box fullList" id="Markets">
                                <h3 class="border-bottom pb-3 mb-3">{{$t('user_guide_markets.markettitle')}}</h3>
                                <!-- Accordion item -->
                                <div class="selected support_sec">
                                    <p>{{$t('user_guide_markets.marketcontent1')}}</p>
                                    <p>{{$t('user_guide_markets.marketcontent2')}} </p>
                                    <p>{{$t('user_guide_markets.marketcontent3')}}</p>
                                    <p>{{$t('user_guide_markets.marketcontent4')}} </p>
                                    <p>{{$t('user_guide_markets.marketcontent5')}} </p>
                                    <p>{{$t('user_guide_markets.marketcontent6')}} </p>
                                    <p>{{$t('user_guide_markets.marketcontent7')}} </p>
                                </div>
                            </section>
                            <!-- Main item -->
                            <section class="inner-box fullList" id="Community">
                                <h3 class="border-bottom pb-3 mb-3">{{$t('user_guide_community.communitytitle')}}</h3>
                                <!-- Accordion item -->
                                <div class="selected support_sec">
                                    <p>{{$t('user_guide_community.communitycontent1')}} </p>
                                    <ul class="dotList">
                                        <li>{{$t('user_guide_community.communitycontent2')}}</li>
                                        <li>{{$t('user_guide_community.communitycontent3')}}</li>
                                        <li>{{$t('user_guide_community.communitycontent4')}}</li>
                                        <li>{{$t('user_guide_community.communitycontent5')}}</li>
                                    </ul>
                                    <p>{{$t('user_guide_community.communitycontent6')}}</p>
                                    <p>{{$t('user_guide_community.communitycontent7')}}</p>
                                </div>
                            </section>
                            <!-- Main item -->
                            <section class="inner-box fullList" id="Account">
                                <h3 class="border-bottom pb-3 mb-3">{{$t('user_guide_account.accounttitle')}} </h3>
                                <!-- Accordion item -->
                                <div class="selected support_sec">
                                    <h4>{{$t('user_guide_account.accountcontent1')}}</h4>
                                    <p>{{$t('user_guide_account.accountcontent2')}} </p>
                                    <p>{{$t('user_guide_account.accountcontent3')}} </p>
                                    <ul class="dotList">
                                        <li>{{$t('user_guide_account.accountcontent4')}} <a href="/leaders" class="secondary">{{$t('user_guide_account.accountcontent4a')}}</a></li>
                                        <li>{{ $t('user_guide_account.accountcontent5') }}  <a href="/leaders" class="secondary">{{$t('user_guide_account.accountcontent5a')}}</a></li>
                                    </ul>
                                    <p> {{$t('user_guide_account.accountcontent6')}}</p>
                                    <p> {{$t('user_guide_account.accountcontent7')}}</p>
                                    <h4> {{$t('user_guide_account.accountcontent8')}}</h4>
                                    <p> {{$t('user_guide_account.accountcontent9')}}</p>
                                    <p> {{$t('user_guide_account.accountcontent10')}}</p>
                                    <p> {{$t('user_guide_account.accountcontent11')}}</p>
                                    <h4> {{$t('user_guide_account.accountcontent12')}}</h4>
                                    <div class="inText">
                                        <h5> {{$t('user_guide_account.accountcontent13')}}</h5>
                                        <div class="inText">
                                            <h6> {{$t('user_guide_account.accountcontent14')}}</h6>
                                            <p> {{$t('user_guide_account.accountcontent15')}}</p>
                                            <h6> {{$t('user_guide_account.accountcontent16')}}</h6>
                                            <p> {{$t('user_guide_account.accountcontent17')}}</p>
                                        </div>
                                        <h5> {{$t('user_guide_account.accountcontent18')}}</h5>
                                        <p> {{$t('user_guide_account.accountcontent19')}}</p>
                                        <ul class="inText dotList">
                                            <li> {{$t('user_guide_account.accountcontent20')}}</li>
                                            <li> {{$t('user_guide_account.accountcontent21')}}</li>
                                            <li> {{$t('user_guide_account.accountcontent22')}}</li>
                                        </ul>
                                        <h5> {{$t('user_guide_account.accountcontent23')}}</h5>
                                        <p v-html="$t('user_guide_account.accountcontent24')"></p>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
 
</template>
<script>
import $ from 'jquery'
export default {
    setup() {

    },
    data() {
        return {
            ZuluTradeTabs : 'Onboarding'
        };
    },
    mounted() {
        $('.js-anchor-link').click(function(e){
            e.preventDefault();
            var target = $($(this).attr('href'));
            if(target.length){
                var scrollTo = target.offset().top;
                $('body, html').animate({scrollTop: scrollTo+'px'}, 800);
            }
        });

        // Get all sections that have an ID defined
        const sections = document.querySelectorAll("section[id]");

        // Add an event listener listening for scroll
        window.addEventListener("scroll", navHighlighter);

        function navHighlighter() {
        
        // Get current scroll position
        let scrollY = window.pageYOffset;
        
        // Now we loop through sections to get height, top and ID values for each
        sections.forEach(current => {
            const sectionHeight = current.offsetHeight;
            const sectionTop = current.offsetTop - 50;
            var sectionId = current.getAttribute("id");
            
            /*
            - If our current scroll position enters the space where current section on screen is, add .active class to corresponding navigation link, else remove it
            - To know which link needs an active class, we use sectionId variable we are getting while looping through sections as an selector
            */
            if (
                scrollY > sectionTop &&
                scrollY <= sectionTop + sectionHeight
            ){
                document.querySelector(".repeatList a[href*=" + sectionId + "]").classList.add("active");
            } else {
                document.querySelector(".repeatList a[href*=" + sectionId + "]").classList.remove("active");
            }
        });
        }
    },
}
</script>